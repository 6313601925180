export const uniquenessOptions = [
  {
    value: 'unique',
    label: 'Unique',
  },
  {
    value: 'blend',
    label: 'Blend',
  },
  {
    value: 'exact',
    label: 'Exact',
  },
];

export const imageProviderOptions = [
  {
    value: undefined,
    label: 'Default',
  },
  {
    value: 'fluxPro',
    label: 'Flux Pro',
  },
];
