import { Button, ModalFooter } from '@chakra-ui/react';
import { Modal, ModalBody, useMaestroToast } from '@maestro/components';
import {
  StoryFactoryShortFragment,
  useCreateStoryFactoryMutation,
  useGetStoryFactoryQuery,
} from '@maestro/graphql';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  factory?: StoryFactoryShortFragment;
  onClose: () => void;
};

export const StoryFactoryDuplicateModal: React.FC<Props> = (props) => {
  const { factory, onClose } = props;
  const isOpen = !!factory;
  const { data } = useGetStoryFactoryQuery(
    { id: factory?.id ?? '' },
    { enabled: !!factory },
  );
  const { mutateAsync } = useCreateStoryFactoryMutation();
  const toast = useMaestroToast();
  const navigate = useNavigate();

  const onConfirm = async () => {
    if (data?.storyFactory) {
      const { storyFactory } = data;
      const result = await mutateAsync({
        input: {
          factoryStrategy: 'v2',
          fields: storyFactory.fields,
          jsonSchema: storyFactory.jsonSchema,
          prompt: storyFactory.prompt,
          systemPrompt: storyFactory.systemPrompt,
          title: `${storyFactory.title} (copy)`,
          promptSlug: storyFactory.promptSlug,
        },
      });

      if (result.createFactory.storyFactory?.id) {
        toast({ title: 'Factory duplicated', status: 'success' });
        navigate(`/story-factory/${result.createFactory.storyFactory.id}`);
      }
    }
  };

  return (
    <Modal title="Play story" isOpen={isOpen} onClose={onClose} size="2xl">
      {factory && (
        <ModalBody>
          <Container>
            <Description>
              You're going to duplicate <strong>{factory.title}</strong>
            </Description>
          </Container>
        </ModalBody>
      )}
      <ModalFooter>
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Duplicate
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  gap: ${dimensions.size24};
  justify-content: space-around;
  align-items: center;
  padding: ${dimensions.size24};
`;

const Description = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.header};
`;
