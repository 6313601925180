import { ColorTheme, RGBA } from '../types/theme';
import { baseColorMap } from './baseColors';
import { fillColorMap } from './fillColors';

export type GameplayColorDesignSystem = {
  solidShade: RGBA;
  solidDefaultFill30: RGBA;
  solidDefaultFill50: RGBA;
  bodyText: RGBA;
};

export const gameplayColorMap: ColorTheme<GameplayColorDesignSystem> = {
  dark: {
    solidShade: 'rgba(44, 44, 55, 1)',
    solidDefaultFill30: 'rgba(40, 40, 51, 1)',
    solidDefaultFill50: 'rgba(44, 44, 55, 1)',
    bodyText: fillColorMap.dark.default['900'],
  },
  light: {
    solidShade: baseColorMap.light.light['1000'],
    solidDefaultFill30: baseColorMap.light.light['30'],
    solidDefaultFill50: baseColorMap.light.light['50'],
    bodyText: fillColorMap.light.default['900'],
  },
};
