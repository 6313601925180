import {
  Menu,
  MenuDropdownButton,
  MenuItem,
  MenuList,
} from '@maestro/components/menu/Menu';
import {
  StoryCategoryFragment,
  useListCategoriesQuery,
} from '@maestro/graphql';
import React from 'react';
import { Category } from './Category';

type Props = {
  value: StoryCategoryFragment | undefined;
  onChange: (value: StoryCategoryFragment) => void;
};

export const CategoryDropdown: React.FC<Props> = ({ value, onChange }) => {
  const { data } = useListCategoriesQuery();
  const categories = data?.storyCategories?.categories ?? [];

  return (
    <Menu>
      <MenuDropdownButton>
        {value && <Category category={value} />}
      </MenuDropdownButton>

      <MenuList>
        {categories.map((category) => (
          <MenuItem key={category.name} onClick={() => onChange(category)}>
            <Category category={category} />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
