import { Switch } from '@chakra-ui/react';
import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { Field, Textarea } from '@maestro/components';
import React from 'react';
import { ImageProviderSelectionField } from './ImageProviderSelectionField';

type Props = {
  onStudioNodeDataChange: (studioNode: StudioNodeData) => void;
  studioNodeData: StudioNodeData;
};

export const GenerateImageWithAiFields: React.FC<Props> = (props) => {
  const { studioNodeData } = props;

  const isStatBlock = studioNodeData.type === StudioNodeType.StatBlock;
  const shouldGenerateImageSwitch = (
    <Field label="Generate Image">
      <Switch
        size="lg"
        isChecked={!!studioNodeData.generateImage}
        onChange={(e) =>
          props.onStudioNodeDataChange({
            ...studioNodeData,
            generateImage: e.target.checked ? {} : undefined,
          })
        }
      />
    </Field>
  );

  if (isStatBlock && !studioNodeData.generateImage) {
    return shouldGenerateImageSwitch;
  }

  return (
    <>
      <ImageProviderSelectionField
        label="AI Image Provider"
        hint="Pick an ai image provider to generate the image"
        value={studioNodeData.generateImage?.provider}
        onChange={(selection) =>
          props.onStudioNodeDataChange({
            ...studioNodeData!,
            generateImage: {
              ...(studioNodeData.generateImage ?? {}),
              provider: selection,
            },
          })
        }
      />
      <Field label="Image Prompt">
        <Textarea
          placeholder="Tell AI how to generate the image, the context from stat block will be added to this prompt"
          value={
            studioNodeData.type === StudioNodeType.StatBlock
              ? (studioNodeData.generateImage?.prompt ?? '')
              : (studioNodeData.generateImage?.prompt ??
                studioNodeData.aiInstructions ??
                '')
          }
          onChange={(e) =>
            props.onStudioNodeDataChange({
              ...studioNodeData,
              aiInstructions:
                studioNodeData.type === StudioNodeType.Image
                  ? undefined
                  : studioNodeData.aiInstructions,
              generateImage: {
                ...(studioNodeData.generateImage ?? {}),
                prompt: e.target.value,
              },
            })
          }
        />
      </Field>
    </>
  );
};
