import { env } from '@maestro/env';
import { useMaestroToast } from '@maestro/components';

export type GenerateAiImageResult = {
  imageUrl: string;
  imageSize: { width: number; height: number };
};

export const useGenerateImageWithAi = () => {
  const toast = useMaestroToast();

  const generateImage = async (
    prompt: string,
    size: string,
  ): Promise<GenerateAiImageResult> => {
    try {
      const body = JSON.stringify({
        prompt,
        imageSize: size,
        provider: 'fluxPro',
      });

      const response = await fetch(
        `${env.VITE_MAESTRO_API_URL}/studio/generate-image`,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body,
        },
      );

      if (!response.ok) {
        toast({
          status: 'warning',
          title: 'AI image generation failed',
        });

        throw new Error('Failed to generate an image with ai');
      }

      const { imageUrl, imageSize } = await response.json();

      if (!imageUrl) {
        throw Error(
          'Invalid generate image with ai response, imageUrl is missing!',
        );
      }

      return { imageUrl, imageSize };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      toast({
        status: 'warning',
        title: 'AI image generation request failed',
      });

      throw error;
    }
  };

  return { generateImage };
};
