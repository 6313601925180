// TODO
const InterRegular = `
  font-family: 'Inter';
  font-weight: 400;
`;

const InterMedium = `
  font-family: 'Inter';
  font-weight: 500;
`;

const InterSemiBold = `
  font-family: 'Inter';
  font-weight: 600;
`;

const InterBold = `
  font-family: 'Inter';
  font-weight: 700;
`;

const NunitoBold = `
  font-family: 'Nunito';
  font-weight: 700;
`;
const NunitoExtraBold = `
  font-family: 'Nunito';
  font-weight: 800;
`;

const AvenueFont = `
  font-family: 'AvenueFont';
`;

export const typography = {
  NunitoBold,
  NunitoExtraBold,
  AvenueFont,
  InterRegular,
  InterMedium,
  InterSemiBold,
  InterBold,
};
