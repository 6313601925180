import { useMaestroToast } from '@maestro/components';
import {
  StoryFactory,
  UpdateFactoryErrorCode,
  useGetStoryFactoryQuery,
  useUpdateStoryFactoryMutation,
} from '@maestro/graphql';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NotFound } from '../../../components/NotFound';
import { TableLoader } from '../../../components/TableLoader';
import { StoryFactoryForm } from './StoryFactoryForm';

export const StoryFactoryEdit: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetStoryFactoryQuery({ id: id! });
  const { mutateAsync: updateFactory } = useUpdateStoryFactoryMutation();
  const [isSaving, setSaving] = useState(false);
  const toast = useMaestroToast();
  const navigate = useNavigate();

  const save = async (
    factory: Pick<
      StoryFactory,
      | 'prompt'
      | 'fields'
      | 'title'
      | 'jsonSchema'
      | 'systemPrompt'
      | 'promptSlug'
    >,
  ) => {
    const { prompt, systemPrompt, fields, title, jsonSchema, promptSlug } =
      factory;

    setSaving(true);

    try {
      const { updateFactory: result } = await updateFactory({
        id: id!,
        input: { prompt, fields, title, jsonSchema, systemPrompt, promptSlug },
      });

      if (result.error) {
        const [error] = result.error;

        toast({
          title:
            error.code === UpdateFactoryErrorCode.InvalidJsonSchema
              ? 'JSON'
              : 'Failed to save factory',
          description: error.message,
          status: 'warning',
        });
      } else {
        toast({ title: 'Factory saved', status: 'success' });
      }
    } finally {
      setSaving(false);
    }
  };

  if (isLoading) {
    return <TableLoader />;
  } else if (!data?.storyFactory) {
    return <NotFound />;
  }

  return (
    <StoryFactoryForm
      formTitle="Editing Story Factory"
      title={data.storyFactory.title}
      prompt={data.storyFactory.prompt}
      promptSlug={data.storyFactory.promptSlug ?? undefined}
      systemPrompt={data.storyFactory.systemPrompt}
      fields={data.storyFactory.fields}
      jsonSchema={data.storyFactory.jsonSchema}
      onSave={save}
      onCancel={() => navigate(`/story-factory/${id}`)}
      isSaving={isSaving}
    />
  );
};
