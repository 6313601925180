import type {
  StatBlockData,
  Dice,
  EndEpisodeAction,
} from '@common/studio-types';
import type { Attribute } from './gameConfig';
import type { CoinTossSide } from './index';

export enum Role {
  Narrator = 'narrator',
  Character = 'character',
  Player = 'player',
}

export type TextMessage = {
  nodeId: string;
  type: 'text';
  name: string;
  role: Role;
  message: string;
};

export type ImageMessage = {
  nodeId: string;
  type: 'image';
  alt?: string;
  imageUrl: string;
  width?: number;
  height?: number;
};

export type EndEpisodeMessage = {
  nodeId?: string;
  type: 'end-episode';
  message: string;
  action: EndEpisodeAction;
};

export type EpisodeProgressMessage = {
  type: 'episode-progress';
  progressPercentage: number; // integer 0-100
};

export type InventoryItemChangeMessage = {
  nodeId: string;
  type: 'inventory-item-change';
  itemId: string;
  delta: number;
};

export type PlayerAttributeMessage = {
  nodeId: string;
  type: 'player-attribute';
  attribute: Attribute;
  delta: number;
};

export type SingleSelectAddonCoinToss = {
  type: 'coin-toss';
  attribute?: Attribute;
};

export type SingleSelectAddon = SingleSelectAddonCoinToss;

export type SingleSelectOption = {
  id: string;
  text: string;
  addon?: SingleSelectAddon;
};

export type SingleSelectInteractionMessage = {
  nodeId: string;
  type: 'single-select-interaction';
  id: string;
  prompt: string;
  options: SingleSelectOption[];
};

export type SelectedInteractionOptionMessage = {
  type: 'selected-interaction-option';
  prompt: string;
  selectedOption: SingleSelectOption;
};

export type PlayerInputMessage = {
  nodeId: string;
  id: string;
  type: 'player-input';
  prompt: string;
  maxLength?: number;
};

export type PlayerInputResultMessage = {
  nodeId: string;
  id: string;
  type: 'player-input-result';
  input: string;
};

export type CoinTossResultMessage = {
  nodeId: string;
  id: string;
  type: 'coin-toss-result';
  choice: CoinTossSide;
  result: CoinTossSide;
  successful: boolean;
  confirmed: boolean;
  attribute?: Attribute;
  successRate: number; // 0-100
};

export type CoinTossInteractionMessage = {
  nodeId: string;
  id: string;
  type: 'coin-toss-interaction';
  prompt: string;
  attribute?: Attribute;
  successRate: number; // 0-100
};

export type DiceRollResult = {
  dice: Dice;
  result: number;
};

export type DiceRollInteractionMessage = {
  nodeId: string;
  id: string;
  type: 'dice-roll-interaction';
  prompt: string;
  classDifficulty?: number;
  dices: Dice[];
  modifier: number;
};

export type DiceRollResultMessage = {
  nodeId: string;
  id: string;
  type: 'dice-roll-result';
  classDifficulty?: number;
  result: DiceRollResult[];
  modifier: number;
};

export type StatBlockMessage = {
  nodeId: string;
  id: string;
  type: 'stat-block';
  data: StatBlockData;
};

export type Message =
  | TextMessage
  | PlayerInputMessage
  | PlayerInputResultMessage
  | SingleSelectInteractionMessage
  | SelectedInteractionOptionMessage
  | EndEpisodeMessage
  | EpisodeProgressMessage
  | PlayerAttributeMessage
  | InventoryItemChangeMessage
  | CoinTossInteractionMessage
  | CoinTossResultMessage
  | ImageMessage
  | DiceRollResultMessage
  | DiceRollInteractionMessage
  | StatBlockMessage;
