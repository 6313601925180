import { StoryCategoryFragment } from '@maestro/graphql';
import { dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';

type Props = {
  category: StoryCategoryFragment;
};

export const Category: React.FC<Props> = ({ category }) => {
  return (
    <Container>
      <CategoryIcon src={category.iconUrl} />
      <Name>{category.name}</Name>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${dimensions.size8};
`;

const Name = styled.div`
  ${textStyles.body.b12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const CategoryIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;
