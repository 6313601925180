import { Button, ButtonGroup, ButtonProps, Wrap } from '@chakra-ui/react';
import { dimensions } from '@maestro/styles';
import React from 'react';

type Props<T> = Omit<ButtonProps, 'onChange' | 'value'> & {
  options: { label: string; value: T; isDisabled?: boolean }[];
  value: T;
  onChange: (value: T) => void;
};

const activeProps = { bg: 'background.accent' };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RadioButtons: React.FC<Props<any>> = (props) => {
  const { options, value, onChange, ...buttonProps } = props;

  return (
    <ButtonGroup>
      <Wrap>
        {options.map((option) => (
          <Button
            {...buttonProps}
            key={option.value || ''}
            size="sm"
            isDisabled={option.isDisabled}
            variant="default"
            fontSize={dimensions.size12}
            isActive={value === option.value}
            _active={activeProps}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </Wrap>
    </ButtonGroup>
  );
};
