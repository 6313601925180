import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { toOptimizedImageUri } from '@common/image';
import {
  HeaderContainer,
  HeaderImage,
  HeaderRowContainer,
  HeaderTitle,
  Icon,
  ImageWrapper,
  Link,
  ScreenContainer,
  SectionCard,
  SectionContainer,
  SectionTitle,
} from '@maestro/components';
import {
  FieldDefinition,
  useGetStoryFactoryBranchQuery,
} from '@maestro/graphql';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NotFound } from '../../../components/NotFound';
import { TableLoader } from '../../../components/TableLoader';
import { Category } from './Category';
import { PlayStoryModal } from './PlayStoryModal';
import { PublishStoryModal } from './PublishStoryModal';
import { StoryStatus } from './StoryFactoryBranchStatus';

export const StoryFactoryBranchDetails: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetStoryFactoryBranchQuery({ id: id! });
  const play = useDisclosure();
  const publish = useDisclosure();
  const fields: Record<string, FieldDefinition> = useMemo(
    () =>
      data?.storyFactoryBranch?.storyFactory.fields.reduce((acc, field) => {
        return { [field.id]: field, ...acc };
      }, {}) ?? {},
    [data?.storyFactoryBranch?.storyFactory.fields],
  );

  if (isLoading) {
    return <TableLoader />;
  }

  if (!data?.storyFactoryBranch) {
    return <NotFound />;
  }

  return (
    <ScreenContainer>
      <PlayStoryModal
        isOpen={play.isOpen}
        onClose={play.onClose}
        story={data.storyFactoryBranch}
      />
      <PublishStoryModal
        type={data.storyFactoryBranch.publishedAt ? 'unpublish' : 'publish'}
        isOpen={publish.isOpen}
        onClose={publish.onClose}
        story={data.storyFactoryBranch}
      />
      <HeaderContainer>
        <HeaderRowContainer>
          <ImageWrapper borderRadius={rawDimensions.size8}>
            <HeaderImage
              $width={160}
              $height={240}
              src={toOptimizedImageUri(data.storyFactoryBranch.coverImage, {
                width: rawDimensions.size160,
                height: rawDimensions.size240,
                dpr: window.devicePixelRatio,
                resize: 'cover',
              })}
            />
          </ImageWrapper>
          <HeaderColumnContainer>
            <div>
              <StoryStatus story={data.storyFactoryBranch} />
            </div>
            {data.storyFactoryBranch.category && (
              <Category category={data.storyFactoryBranch.category} />
            )}
            <HeaderTitle>{data.storyFactoryBranch.title}</HeaderTitle>
            <ButtonGroup>
              <Link to={`/story/${id}/edit`}>
                <Button variant="default">Edit</Button>
              </Link>
              <Button
                onClick={play.onOpen}
                leftIcon={<Icon name="publish" size={rawDimensions.size16} />}
                variant="default"
              >
                Play
              </Button>
              <Button
                onClick={publish.onOpen}
                leftIcon={<Icon name="publish" size={rawDimensions.size16} />}
                variant={
                  data.storyFactoryBranch.publishedAt ? 'danger' : 'primary'
                }
              >
                {data.storyFactoryBranch.publishedAt ? 'Unpublish' : 'Publish'}
              </Button>
            </ButtonGroup>
          </HeaderColumnContainer>
        </HeaderRowContainer>
      </HeaderContainer>

      <SectionContainer>
        <SectionTitle>Factory</SectionTitle>
        <SectionCard>{data.storyFactoryBranch.storyFactory.title}</SectionCard>
        <SectionTitle>Fields</SectionTitle>
        <FieldCard>
          {data.storyFactoryBranch.fields.map((field) => (
            <FieldContainer key={field.fieldId}>
              <FieldLabel>{fields[field.fieldId]?.name}</FieldLabel>
              <div>{field.value}</div>
            </FieldContainer>
          ))}
        </FieldCard>
      </SectionContainer>
    </ScreenContainer>
  );
};

const HeaderColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
`;

const FieldCard = styled(SectionCard)`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size24};
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size6};
`;

const FieldLabel = styled.div`
  ${textStyles.label.lb16sb};
  color: ${({ theme }) => theme.colors.text.header};
`;
