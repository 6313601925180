import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { UploadedImage } from '../hooks/useUploadImage';
import { Icon } from '../Icon';
import { RadioButtons } from '../RadioButtons';
import { Textarea } from '../Textarea';
import {
  GenerateAiImageResult,
  useGenerateImageWithAi,
} from './hooks/useGenerateImageWithAi';

type Props = {
  onImageSelect: (image: UploadedImage) => void;
};

export const CreateWithAi: React.FC<Props> = ({ onImageSelect }) => {
  const [prompt, setPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState('');
  const { generateImage } = useGenerateImageWithAi();
  const [image, setGeneratedImage] = useState<
    GenerateAiImageResult | undefined
  >();
  const [imageSize, setImageSize] = useState('COVER_IMAGE');
  const imageSizes = [
    { label: 'Square SM', value: 'SQUARE_SM' },
    { label: 'Square MD', value: 'SQUARE_MD' },
    { label: 'Square LG', value: 'SQUARE_LG' },
    { label: 'Cover Image', value: 'COVER_IMAGE' },
    { label: 'Landscape LG', value: 'LANDSCAPE_LG' },
    { label: 'Scene Image', value: 'LANDSCAPE_IMAGE_SCENE' },
  ];

  const onUseClick = () => {
    if (image) {
      onImageSelect({
        path: image.imageUrl,
        height: image.imageSize.height,
        width: image.imageSize.width,
      });
    }
  };

  const onGenerate = () => {
    setIsGenerating(true);
    setGeneratedImage(undefined);
    generateImage(prompt, imageSize)
      .then(setGeneratedImage)
      .catch(() => setError('Error while generating image'))
      .finally(() => setIsGenerating(false));
  };

  return (
    <FormControl isInvalid={!!error}>
      <AiContainer>
        <Textarea
          value={prompt}
          placeholder="Animated 3d-stylographic poster. The title: 'Dra..."
          onChange={(e) => setPrompt(e.target.value)}
          isInvalid={!!error}
        />
        <RadioButtons
          options={imageSizes}
          value={imageSize}
          onChange={(size) => setImageSize(size)}
        />
        {image && <Image src={image.imageUrl} alt="Generated image" />}
        <ButtonGroup>
          {image && (
            <Button variant="primary" onClick={onUseClick}>
              Use
            </Button>
          )}
          <Button
            variant={image ? 'default' : 'primary'}
            onClick={onGenerate}
            isDisabled={!prompt}
            isLoading={isGenerating}
            leftIcon={
              <Icon
                name={image ? 'arrow-counterclockwise' : 'ai-generate'}
                size={rawDimensions.size16}
              />
            }
          >
            {image ? 'Regenerate' : 'Generate'}
          </Button>
        </ButtonGroup>
      </AiContainer>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

const AiContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: end;
`;

const Image = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  border-radius: ${dimensions.size8};
`;
