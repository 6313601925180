import { Button } from '@chakra-ui/react';
import {
  FormControl,
  HeaderContainer,
  HeaderRowContainer,
  HeaderTitle,
  ImageInput,
  ScreenContainer,
  SectionCard,
  SectionContainer,
  SectionTitle,
  TextInput,
} from '@maestro/components';
import {
  FieldDefinition,
  FieldValue,
  StoryCategoryFragment,
  StoryFactoryBranchFragment,
} from '@maestro/graphql';
import { dimensions } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CategoryDropdown } from './CategoryDropdown';
import { StoryForm } from './StoryForm';

type Props = {
  fieldDefinition: FieldDefinition[];
  formTitle: string;
  isSaving?: boolean;
  title: string;
  coverImage: string;
  category?: StoryCategoryFragment | null;
  fields: FieldValue[];
  onSave: (
    value: Pick<
      StoryFactoryBranchFragment,
      'fields' | 'title' | 'coverImage' | 'category'
    >,
  ) => void;
  onCancel: () => void;
};

export const StoryFactoryBranchForm: React.FC<Props> = (props) => {
  const [title, setTitle] = useState('');
  const [coverImage, setCoverImage] = useState<string | undefined>('');
  const [category, setCategory] = useState<StoryCategoryFragment | undefined>();
  const [isDirty, setDirty] = useState(false);
  const [state, setState] = useState<Record<string, string | number>>({});

  useEffect(() => {
    const newState: Record<string, string> = {};

    props.fields.forEach(({ fieldId, value }) => {
      const fieldDef = props.fieldDefinition.find(({ id }) => id === fieldId);

      if (fieldDef) {
        newState[fieldDef.id] = value;
      }
    });

    setCategory(props.category ?? undefined);
    setDirty(false);
    setState(newState);
  }, [props.fields, props.fieldDefinition]);

  useEffect(() => {
    setTitle(props.title);
    setDirty(false);
  }, [props.title]);

  useEffect(() => {
    setCoverImage(props.coverImage);
    setDirty(false);
  }, [props.coverImage]);

  const onTitleChanged = (title: string) => {
    setTitle(title);
    setDirty(true);
  };

  const onCoverChanged = (coverImage?: string) => {
    setCoverImage(coverImage);
    setDirty(true);
  };

  const onCategoryChanged = (category: StoryCategoryFragment) => {
    setCategory(category);
    setDirty(true);
  };

  const onFieldsChange = (fieldId: string, value: string | number) => {
    setState({ ...state, [fieldId]: value });
    setDirty(true);
  };

  const onSave = () => {
    if (coverImage) {
      const values: FieldValue[] = [];

      props.fieldDefinition.forEach((field) => {
        const value = state[field.id];

        if (value) {
          values.push({ fieldId: field.id, value: String(value) });
        }
      });

      props.onSave({ coverImage, fields: values, title, category });
      setDirty(false);
    }
  };

  return (
    <ScreenContainer>
      <HeaderContainer>
        <StyledHeaderRowContainer>
          <HeaderTitle>{props.formTitle}</HeaderTitle>
          <Divider />
          <Button variant="default" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            isLoading={props.isSaving}
            onClick={onSave}
            isDisabled={!isDirty || !coverImage}
          >
            Save
          </Button>
        </StyledHeaderRowContainer>
      </HeaderContainer>

      <SectionContainer>
        <SectionTitle>Details</SectionTitle>

        <SectionCard>
          <FormControl
            label="Title"
            object={{ title }}
            field="title"
            errorMessage="Write a story title"
          >
            <TextInput
              maxCharacters={50}
              value={title}
              onChange={(e) => onTitleChanged(e.target.value)}
              placeholder="Create your own"
            />
          </FormControl>
          <FormControl
            label="Category"
            object={{ category }}
            field="category"
            errorMessage="Select a story category"
          >
            <CategoryDropdown
              value={category}
              onChange={(value) => onCategoryChanged(value)}
            />
          </FormControl>
          <FormControl
            label="Cover image"
            noMargin
            formControlProps={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'flex-start',
              height: '100%',
            }}
            errorMessage="Add a cover image"
          >
            <ImageInput
              value={coverImage}
              uploadPath="series"
              onChange={(image) => onCoverChanged(image?.path)}
              insideFlexContainer
              containerAspectRatio={{
                width: dimensions.size200,
                height: dimensions.size300,
              }}
            />
          </FormControl>
        </SectionCard>
        <SectionTitle>Form Fields</SectionTitle>
        <SectionCard>
          <StoryForm
            allowEmpty
            values={state}
            onChange={onFieldsChange}
            fieldDefinition={props.fieldDefinition}
          />
        </SectionCard>
      </SectionContainer>
    </ScreenContainer>
  );
};

const Divider = styled.div`
  flex: 1;
`;

const StyledHeaderRowContainer = styled(HeaderRowContainer)`
  align-items: center;
  width: 100%;
`;
